import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@chakra-ui/react';
import config from '../../../config';
import RecentProjects from './recent-projects';
import Templates from './templates';
import { useRecoilValue } from 'recoil';
import { newProjectState } from '../../../store';
import { WithProjectModalHoc } from './new-project-hoc';

const Dashboard = () => {
  const { user } = useAuth0();
  const isOpen = useRecoilValue(newProjectState);

  if (user?.[config.auth.claims.loginsCount] === 1) {
    return <Redirect to={'/onboarding'} />;
  }

  return (
    <Box>
      <RecentProjects />
      <Templates />
    </Box>
  );
};

export default WithProjectModalHoc(Dashboard);
