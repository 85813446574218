import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { first, isEmpty } from 'lodash';
// import { useErrorHandler } from 'react-error-boundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Heading } from '@chakra-ui/react';
import useProjects from 'hooks/use-projects';
import useTempalteFiles from 'hooks/use-template-files';
import { IconButton, MenuItem, Menu } from 'components';
import { TemplatePreview } from './template-preview';
import { TemplateLoadingSpinner } from './loading-spinner';

const TemplatetActions = ({
  setIsActive,
  setIsOpen,
  isActive,
  isOpen,
  title,
  videoFiles = '',
  user,
  pexelLink,
  videoId,
  duration,
}) => {
  const [baseElement, setBaseElement] = useState(null);
  const { postProject, currentProject } = useProjects();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [videoUploading, setVideoUploading] = React.useState(false);

  const {
    isLoading: templateUploading,
    isSuccess: templateSuccess,
    data: templateUploadResponse,
    isError: templateUploadError,
    mutateAsync: postDataAsyncFunc,
  } = useTempalteFiles();

  let recommendedVidFiles = videoFiles.filter((f) => f.width > 1900);
  if (isEmpty(recommendedVidFiles)) {
    recommendedVidFiles = videoFiles.filter((f) => f.width > 1000);
  }
  if (isEmpty(recommendedVidFiles)) {
    recommendedVidFiles = videoFiles.filter((f) => f.height > 1000);
  }
  if (isEmpty(recommendedVidFiles)) {
    recommendedVidFiles = videoFiles;
  }

  const pexelVideoFile = first(recommendedVidFiles, {});
  const ref = useOnclickOutside(
    () => {
      setIsActive(false);
      setIsOpen(false);
    },
    { disabled: !isOpen }
  );

  const handleOnNewProject = (id) => {
    window.location.replace(`https://app.clippy.io/canvas/${id}`);
  };

  React.useEffect(() => {
    const { isLoading, isSuccess, isError, id } = currentProject;
    if (isSubmitted) {
      if (!isLoading && isSuccess && !!id) {
        handleOnNewProject(id);
        setIsSubmitted(false);
      } else if (!isLoading && isError) {
        setIsSubmitted(false);
      }
    }
  }, [isSubmitted, currentProject]);

  React.useEffect(() => {
    if (videoUploading) {
      if (templateSuccess) {
        const { width, height } = pexelVideoFile;
        const { data } = templateUploadResponse || {};
        postProject({
          defaults: {
            backgroundColor: '#000',
          },
          totalDuration: Number(duration),
          title: title,
          size: {
            width: Number(width),
            height: Number(height),
          },
          compositionData: {
            videos: [],
          },
          template: {
            ...pexelVideoFile,
            ...data,
            duration: Number(duration),
          },
        });
        setIsSubmitted(true);
        setVideoUploading(false);
      }
    }
  }, [
    duration,
    pexelVideoFile,
    postProject,
    title,
    videoUploading,
    templateUploading,
    templateSuccess,
    templateUploadError,
    templateUploadResponse,
  ]);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsActive((prev) => !prev);
    setIsOpen((prev) => !prev);
  };

  const handleStartWithTemplate = async () => {
    setVideoUploading(true);
    setIsActive((prev) => !prev);
    setIsOpen((prev) => !prev);
    await postDataAsyncFunc({
      fileUrl: pexelVideoFile.link,
      pexelUrl: pexelLink,
      id: videoId,
    });
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  return (
    <Box position={'absolute'} right={'0.5rem'} top={'0.5rem'}>
      <IconButton
        ref={setBaseElement}
        onClick={handleClick}
        ariaLabel="actions"
        size="xs"
        variant={'solid'}
        isRound
        colorScheme={'blue'}
        opacity={isActive || isOpen ? 1 : 0}
        transition={'opacity 200ms ease'}
        icon={<FontAwesomeIcon icon={['fas', 'ellipsis-h']} size="sm" />}
      />

      <Menu
        ref={ref}
        baseElement={baseElement}
        isOpen={isOpen}
        overrideStyles={{
          nav: { minWidth: '12rem' },
        }}
      >
        <TemplateInfo title={title} />
        <MenuItem
          title={'Start with this template'}
          icon={'play'}
          onClick={handleStartWithTemplate}
        />
        <MenuItem
          title={'Preview this template'}
          icon={'eye'}
          onClick={handlePreview}
        />
      </Menu>
      <TemplatePreview
        isOpen={showPreview}
        onClose={() => setShowPreview(false)}
        videoPreviewLink={pexelVideoFile.link}
        user={user}
        pexelLink={pexelLink}
      />
      <TemplateLoadingSpinner isOpen={videoUploading} />
    </Box>
  );
};

const TemplateInfo = ({ title }) => (
  <Box borderBottom={'1px solid #2C2D37'} p={'1rem'} textAlign={'left'}>
    <Heading as="h1" size="md" isTruncated pb={'0.25rem'} fontWeight={700}>
      {title}
    </Heading>
  </Box>
);

export default TemplatetActions;
