import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@chakra-ui/react';
import config from '../../../config';
import { UserProjects } from './UserProjects';
import { WithProjectModalHoc } from 'views/dashboard/components/new-project-hoc';

export const AllProjects = WithProjectModalHoc(() => {
  const { user } = useAuth0();

  if (user?.[config.auth.claims.loginsCount] === 1) {
    return <Redirect to={'/onboarding'} />;
  }
  return (
    <Box p={10}>
      <UserProjects />
    </Box>
  );
});
