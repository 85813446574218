import React, { useState } from 'react';
import { Link } from '@chakra-ui/react';
import {
  Card,
  CardContent,
  CardPhoto,
  CardFooter,
  CardFooterTitle,
  CardFooterTimestamp,
} from 'components';
import ProjectActions from './project-actions';
import userProjects from '../../../hooks/use-projects';

const Project = ({
  coverPhoto,
  title,
  updatedAt,
  nickname,
  projectId,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { allProjects, postRemotionProject, trashProject } = userProjects();

  const handleMouseEnter = () => setIsActive(true);

  const handleMouseLeave = () => {
    if (isOpen) return;
    setIsActive(false);
  };

  const handleCopy = () => {
    setIsActive(false);
    setIsOpen(false);
    postRemotionProject(allProjects, projectId);
  };

  const handleDelete = () => {
    setIsActive(false);
    setIsOpen(false);
    trashProject(allProjects, projectId);
  };

  const handleExport = () => {
    setIsActive(false);
    setIsOpen(false);
  };

  const handleOnProjectClick = () => {
    window.location.replace(`https://app.clippy.io/canvas/${projectId}`);
  };

  return (
    <Card
      cursor={'pointer'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      <CardContent>
        <CardPhoto
          src={coverPhoto}
          title={title}
          onClick={handleOnProjectClick}
        />
        <ProjectActions
          isActive={isActive}
          isOpen={isOpen}
          setIsActive={setIsActive}
          setIsOpen={setIsOpen}
          title={title}
          nickname={nickname}
          onCopy={handleCopy}
          onExport={handleExport}
          onRemove={handleDelete}
        />
      </CardContent>
      <CardFooter>
        <CardFooterTitle>
          <Link href={`https://app.clippy.io/canvas/${projectId}`}>
            {title}
          </Link>
        </CardFooterTitle>
        <CardFooterTimestamp>{updatedAt}</CardFooterTimestamp>
      </CardFooter>
    </Card>
  );
};

export default Project;
