import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faVideo,
  faPlay,
  faPhotoVideo,
  faTrash,
  faPlus,
  faEye,
  faEllipsisH,
  faCopy,
  faFileExport,
  faChevronRight,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faUserCircle,
  faLock,
  faBell,
  faCreditCard,
  faTimesCircle,
  faCheckCircle,
  faGlobe,
  faQuestionCircle,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faSnapchat,
  faYoutube,
  faLinkedin,
  faTwitter,
  faCcMastercard,
  faCcVisa,
  faCcJcb,
  faCcDiscover,
  faCcDinersClub,
  faCcAmex,
} from '@fortawesome/free-brands-svg-icons';

const initFonts = () => {
  library.add(
    faHome,
    faVideo,
    faPlay,
    faEye,
    faPhotoVideo,
    faTrash,
    faPlus,
    faEllipsisH,
    faCopy,
    faFileExport,
    faChevronRight,
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faUserCircle,
    faLock,
    faBell,
    faCreditCard,
    faTimesCircle,
    faCheckCircle,
    faFacebook,
    faInstagram,
    faTiktok,
    faSnapchat,
    faYoutube,
    faLinkedin,
    faTwitter,
    faCcMastercard,
    faCcVisa,
    faCcJcb,
    faCcDiscover,
    faCcDinersClub,
    faCcAmex,
    faGlobe,
    faQuestionCircle,
    faBars
  );
};

export default initFonts;
