import '@fontsource/inter';
import React from 'react';
import ReactDOM from 'react-dom/client';
/// deploy
import reportWebVitals from './reportWebVitals';
import Providers from 'providers';
import initFonts from 'utils/fonts';
import App from 'app';
import './app.css';

initFonts();

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <Providers>
    <App />
  </Providers>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
