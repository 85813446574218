const styles = {
  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },
  colors: {
    brand: {
      primary: {
        50: '#f4faff',
        100: '#eff5ff',
        200: '#e9eeff',
        300: '#dbe0f2',
        400: '#b8bdce',
        500: '#999eaf',
        600: '#707585',
        700: '#5c6170',
        800: '#3d4250',
        900: '#1c212e',
      },
    },
    azure: '#0080FB',
    alabaster: '#F9F9F9',
    charade: '#262630',
    charadeDark2: '#22232D',
    charadeDark3: '#2D2E3C',
    charadeDark4: '#2D2E3B',
    charadeDark5: '#262731',
    charadeDark6: '#272932',

    shark: '#262730',
    darkShark: '#1A1C23',
    razzleRose: '#FF46CE',
    studio: '#6449B6',
    woodsmoke: '#17161D',
    dolphin: '#6B6A79',
    endeavour: '#005DB7',
    danger: '#E53E3E',
    woodsmoke2: '#16151C',
    turquoise: '#00F9CB',
    silverChalice: '#A8A8A8',
    tuna: '#383648',
    violet: '#5D51B7',
    conflowerBlue: '#6B57FF',
    silver: '#C5C5C5',
    manatee: '#9393A2',
    pearl: '#A7A7AA',
    shuttleGray: '#696B77',
    mako: '#44454F',
    midgray: '#696A76',
    marguerite: '#836DC5',
    lightPurple: '#b7aadd',
    gunpowder: '#3c394d',
    gray: '#8C8C8C',
    alto: '#D7D7D7',
    inputBorder: '#2D3748',
  },
};

export default styles;
