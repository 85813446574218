import React from 'react';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';

import Header from 'layouts/shared/header';
import Aside from 'layouts/shared/aside';
import HeaderUserMenu from 'layouts/shared/header/components/header-user-menu';

const Layout = ({ routes, children }) => {
  const location = useLocation();

  return (
    <LayoutWrapper>
      <LayoutHeader>
        <Header>
          <Text
            as={ReactLink}
            to={{
              pathname: `/pricing`,
              state: { background: location },
            }}
            size="md"
            color={'pink.500'}
          >
            Pricing
          </Text>
          <HeaderUserMenu />
        </Header>
      </LayoutHeader>

      <LayoutAside>
        <Aside routes={routes} isFixed />
      </LayoutAside>

      <LayoutMain>
        <LayoutMainScroll>{children}</LayoutMainScroll>
      </LayoutMain>
    </LayoutWrapper>
  );
};

const LayoutWrapper = ({ children }) => (
  <Box
    display={'grid'}
    my={0}
    mx={'auto'}
    minH={'100vh'}
    maxW={'2560px'}
    gridTemplateColumns={'auto minmax(0, 1fr)'}
    gridTemplateRows={'0fr 0fr 1fr 0fr'}
    backgroundColor={'#1A1C22'}
    color={'white'}
  >
    {children}
  </Box>
);

const LayoutHeader = ({ children }) => (
  <Box
    width={'100%'}
    gridColumn={'1/3'}
    gridRow={'1/2'}
    position={'sticky'}
    top={0}
    zIndex={3}
    bg={'#17161D'}
  >
    {children}
  </Box>
);

const LayoutAside = ({ children }) => (
  <Box
    width={'280px'}
    gridRow={'2/5'}
    gridColumn={'1/2'}
    zIndex={1}
    display={['none', 'none', 'none', 'block']}
  >
    {children}
  </Box>
);

const LayoutMain = ({ children }) => (
  <Box
    width={'100%'}
    gridRow={'3/4'}
    gridColumn={['1/3', '2/3']}
    zIndex={1}
    position={'relative'}
  >
    {children}
  </Box>
);

const LayoutMainScroll = ({ children }) => (
  <Box width={'100%'} height={'100%'} position={'absolute'} overflow={'auto'}>
    {children}
  </Box>
);

export default Layout;
