import React from 'react';
import { Box, Flex, Heading, Link, Text, Skeleton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import constants from '../../../constants';
import map from 'lodash/map';
import get from 'lodash/get';
import take from 'lodash/take';
import filter from 'lodash/filter';
import Project from './project';
import { useAuth0 } from '@auth0/auth0-react';
import userProjects from 'hooks/use-projects';
import { getFriendlyTime } from 'utils/date';
import { Link as RouteLink } from 'react-router-dom';
const imageUrls = [
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-001.jpg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-002.jpg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-003.jpg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-004.jpg',
];

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const RecentProjects = () => {
  const { user } = useAuth0();
  const { allProjects, isLoading } = userProjects();
  console.log('all proj', allProjects);
  const handleOnProjectClick = (id) => {
    /// window.location.replace(`https://app.clippy.io/canvas/${id}`);
  };

  return (
    <Box>
      <Flex mb={8} alignItems={'center'} justifyContent={'space-between'}>
        <Heading as={'h1'} fontWeight={700} size={'xl'}>
          My recent projects
        </Heading>
        <Link as={RouteLink} to={'/projects'} colorScheme={'blue'}>
          <Text as={'span'} mr={'0.5rem'}>
            View all projects
          </Text>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} size="sm" />
        </Link>
      </Flex>
      <Skeleton isLoaded={!isLoading} display={'grid'}>
        <Box
          display={'grid'}
          gridTemplateColumns={[
            'repeat(auto-fill, minmax(100%, 1fr))',
            'repeat(auto-fill, minmax(30%, 1fr))',
            'repeat(auto-fill, minmax(30%, 1fr))',
            'repeat(auto-fill, minmax(30%, 1fr))',
            'repeat(auto-fill, minmax(15%, 1fr))',
          ]}
          gridGap={'1.5rem'}
        >
          {!isLoading &&
            map(
              take(
                filter(
                  allProjects,
                  (f) => f.status === constants.status.ACTIVE
                ),
                10
              ),
              (proj, index) => (
                <Project
                  key={index}
                  projectId={proj.id}
                  onClick={() => handleOnProjectClick(proj.id)}
                  coverPhoto={
                    get(proj, 'data.coverPhoto', null) ||
                    imageUrls[getRandomInt(3)]
                  }
                  title={proj.title || `project 0${index}`}
                  updatedAt={getFriendlyTime(proj.updatedAt)}
                  nickname={user.nickname}
                />
              )
            )}
        </Box>
      </Skeleton>
    </Box>
  );
};

export default RecentProjects;
