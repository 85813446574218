import React from 'react';
import NewProjectModal from './new-project-modal';
import { Box } from '@chakra-ui/react';

export const WithProjectModalHoc = (Component) => (props) => {
  return (
    <Box p={10}>
      <Component {...props} />
      <NewProjectModal />
    </Box>
  );
};
